<template>
  <div class="case">
    <banner img="../assets/img/bgtop.jpg" title="案例展示" />
    <div id="selectType">
      <div id="types">
        <ul>
          <li><a href="#" target="_blank">所有案例</a></li>
        </ul>
      </div>
    </div>
    <div class="case-content" v-loading="loading">
      <div id="case-list">
        <ul>
          <li v-for="data in datalist" :key="data">
            <a href="#" @click.prevent="caseClick(data.title,data.created_at,data.content)">
            <div>
              <img :src="data.coverImage">
            </div>
            <div class="case_textdiv">
              <p>{{data.title}}</p>
              <p>{{ new Date(data.created_at).toLocaleDateString() }}</p>
            </div>
            </a>
          </li>
        </ul>
      </div>
      <div id="case-page">
        <el-pagination
            hide-on-single-page
            :current-page="listQuery.pageNum"
            :page-size="listQuery.pageCount"
            @current-change="currPageChange"
            background
            layout="prev, pager, next"
            :total="total" />
      </div>
    </div>
  </div>
</template>
<script>
import Banner from "../../components/Banner";
export default {
  components: {
    Banner
  },
  data() {
    return {
      loading: true,
      listQuery: {
        "pageNum": 1,
        "pageCount": 9,
        "type": 'case'
      },
      total: 0,
      datalist: []
    };
  },
  methods: {
    loadData() {
      this.loading = true;
      this.$axios({
        url:  this.localpath + 'officialweb/searchArticles',
        method: 'post',
        data: JSON.stringify(this.listQuery),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.code === 0) {
          this.datalist = res.data.msg.articles
          this.total = res.data.msg.total
        }
        this.loading = false
      })
    },
    currPageChange(pageNum){
      if(pageNum!==null) {
        this.listQuery.pageNum = pageNum
        this.loadData()
      }
    },
    caseClick(title,date,content){
      localStorage.setItem("type",'news')
      localStorage.setItem("title",title)
      localStorage.setItem("date",date)
      localStorage.setItem("content",content)
      this.$router.push("/detail")
    },
  },
  created() {
    this.loadData();
  }
};
</script>

 <style lang="scss" scoped>
 li {
   list-style: none;
 }

 a {
   text-decoration: none; /* 去除默认的下划线 */
   color: #000; /* 去除默认的颜色和点击后变化的颜色 */
 }
 .el-pagination {
   text-align: center;
 }

.case {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  //background-color: rgb(245,245,245);

  #selectType {
    width: 100%;
    height: 70px;
    background-color: white;
    //border-bottom: 1px solid #ececec;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 10%);
    #types {
      margin: 0 auto;
      width: 1200px;
      height: 70px;

      ul {
        height: 70px;

        li {
          height: 68px;
          float: left;
          text-align: center;
          line-height: 68px;

          a {
            padding: 0 20px;
            display: block;
          //}
          //a:hover,a:active{
            border-bottom: 2px solid #0068b6;
            color: #0068b6;
          }
        }
      }
    }
  }

  &-section {
    width: 100%;

  }

  .case-content {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 50px;
    #case-list{
      ul{
        margin-top: 50px;
        li{
          display: inline-block;
          margin-left: 30px;
          margin-bottom: 36px;
          width: 380px;
          vertical-align: middle;

          //rgb(18 186 186)           rgb(18 186 186)   rgb(214 200 200)
          div:nth-child(1){
            overflow: hidden;
            width: 380px;
            height: 266px;
            img{
              width: 380px;
              height: 265px;
              transition:all 0.3s;
            }
            img:hover{
              transform: scale(1.1);
            }
          }
          .case_textdiv{
            padding: 5px;
            p:nth-child(1){
              height: 20px;
              line-height: 20px;
              font-size: 16px;
              width: 370px;
            }
            p:nth-child(1):hover{
              color: #017bc3;
              text-decoration: underline;
            }

            p:nth-child(2){
              height: 20px;
              line-height: 20px;
              font-size: 14px;
              color: rgb(146,146,146,1);
              width: 370px;
            }
          }
        }
        li:nth-child(3n-2){
          margin-left: 0;
        }
      }
    }
    #case-page{
      margin-top: 10px;
    }
  }

}
</style>
